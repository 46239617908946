<template>
  <section>
    <!-- Search Banner -->
    <div class="main-banner max-w-full bg-cover bg-center py-44 text-center" :style="`background-image: url('${$config.public.publicUrl}/images/banner-bg-image.png')`">
      <form class="mx-auto lg:container" action="/boards" @submit.prevent="searchBoard">
        <input v-model="q" type="text" class="mb-6 w-full border-0 bg-transparent text-center font-oswald text-4xl font-semibold text-white outline-none placeholder:text-center" placeholder="SEARCH HERE...">
        <br>
        <button type="submit" class="rounded bg-primary px-10 py-3 text-sm font-medium text-white lg:px-16 lg:py-4 lg:text-lg">
          Search
        </button>
      </form>
    </div>

    <Container>
      <div class="flex flex-col gap-x-8 lg:flex-row">
        <!-- board activity -->
        <ActivityFeed :boards="boards" class="grow" />

        <div class="mt-10 flex flex-col gap-10">
          <!-- create board button -->
          <div>
            <NuxtLink to="/boards/new" type="button" class="flex h-full w-full flex-col items-center justify-center rounded-md py-10 font-oswald text-2xl text-gray-400 shadow-custom transition hover:bg-primary hover:text-white">
              <PlusCircleIcon class="h-24 w-24" />
              <span class="font-medium">Create a new board</span>
            </NuxtLink>
          </div>

          <!-- social box -->
          <div class="hidden lg:block">
            <div class="flex w-[260px] flex-col divide-y-2  divide-gray-200 rounded-lg  bg-white p-5 shadow-custom">
              <h4 class="py-2 text-lg font-semibold text-primary">
                Find us
              </h4>
              <div class="mt-3 flex flex-col gap-y-5 py-7">
                <a href="https://www.facebook.com/ProjectCredo" target="_blank" class="flex items-center gap-x-3 rounded-md bg-[#3875EA] p-3 font-medium text-white hover:opacity-90">
                  <FaceBookIcon class="h-5 w-5" />
                  <span>Facebook</span>
                </a>
                <a href="https://www.instagram.com/projectcredo" target="_blank" class="flex items-center gap-x-3 rounded-md bg-gradient-to-r from-[#DD6169] to-[#95409E] p-3 font-medium text-white hover:opacity-90">
                  <InstagramIcon class="h-5 w-5" />
                  <span>Instagram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>

    <!-- About us section -->
    <div class="about-section bg-gray-50 py-16 sm:px-0 md:py-24">
      <Container>
        <div class="flex flex-col gap-8 md:flex-row">
          <div class="flex flex-1 items-center justify-center lg:justify-start">
            <h2 class="text-center font-oswald text-3xl font-semibold leading-7 text-primary after:mt-3 after:block  after:h-[4px] after:w-24 after:rounded-full after:bg-primary after:content-[''] lg:text-5xl">
              Make research accessible.
            </h2>
          </div>
          <div class="flex-1">
            <p class="font-base line-clamp-5 text-sm leading-6 lg:line-clamp-none">
              High quality information is invaluable. Our platform makes discussing
              academic research simple and engaging. Discover and share papers with a community.
              Or communicate science to an audience.
            </p>
            <NuxtLink to="/about" class="mt-5 text-right font-semibold text-primary">
              Read more <ArrowNarrowRightIcon class="inline h-5 w-5" />
            </NuxtLink>
          </div>
        </div>
      </Container>
    </div>
  </section>
</template>

<script>
import { useAsyncData } from 'nuxt/app'
import { mapState } from 'pinia'
import PlusCircleIcon from '../components/icons/PlusCircleIcon.vue'
import Container from '~/components/layouts/Container'
import FaceBookIcon from '~/components/icons/FaceBookIcon'
import InstagramIcon from '~/components/icons/InstagramIcon'
import ArrowNarrowRightIcon from '~/components/icons/ArrowNarrowRightIcon'
import ActivityFeed from '~/components/boards/ActivityFeed'
import { useApi } from '~/composables/useApi'
import { useAuthStore } from '~/stores/auth'
import useTitle from '~/composables/useTitle'

export default {
  components: { Container, FaceBookIcon, InstagramIcon, ArrowNarrowRightIcon, ActivityFeed, PlusCircleIcon },

  async setup () {
    useTitle('Home')

    const res = await useAsyncData('activities', async () => {
      return await useApi('/api/activities')
    })

    return { boards: res.data.value }
  },

  data: () => ({
    q: '',
  }),

  computed: {
    ...mapState(useAuthStore, ['user']),
  },

  methods: {
    searchBoard () {
      this.$router.push({ path: '/boards', query: { q: this.q } })
    },
  },
}
</script>
