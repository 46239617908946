<template>
  <div id="activity-feed" class="py-5 sm:px-0 md:py-10">
    <div>
      <div>
        <div class="flex justify-between">
          <h1 class="font-oswald text-3xl font-semibold uppercase md:text-4xl lg:text-5xl">
            Board Activity
          </h1>
        </div>
        <div class="mt-10 flex flex-col gap-y-7">
          <BoardCard
            v-for="board in boards"
            :key="board.id"
            :board="board"
            :signed-in="signedIn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoardCard from '~/components/boards/BoardCard'

export default {
  components: { BoardCard },

  props: {
    boards: { type: Array, required: true },
    signedIn: Boolean,
  },

  data () {
    return {
      filterPins: false,
    }
  },
}
</script>
